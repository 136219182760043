<template>
<div>
  <v-container fluid>
    <!-- <h1> AWS Upload</h1>
     <input type="file" style="font-family:Arial" ref="uploadFile" @change="previewImage" accept="application/pdf,.xls, .xlsx"/>
     <v-btn @click="uploadFile()"> Upload </v-btn> -->

      <!-- Server Pagination -->
            <a-row xs="24" :sm="12" :md="10" :gutter="5">
            <!-- <v-card v-resize="onResize" > -->
              <v-simple-table  dense fixed-header :height="screenSize - 290" >
                  <!-- <template v-slot:default> -->
                  <thead>
                      <tr>
                          <th class="text-center">
                              House Type
                          </th>
                          <th class="text-center">
                              Category 1
                          </th>
                          <th class="text-center">
                              Category 2
                          </th>
                            <th class="text-center">
                              Japanese
                          </th>
                            <th class="text-center">
                              Romanji
                          </th>
                            <th class="text-center">
                              English
                          </th>
                            <th class="text-center">
                              Abbr
                          </th>
                            <th class="text-center">
                              CAD Request 
                          </th>
                            <th class="text-center">
                              Floor Plan/Elevation
                          </th>
                            <th class="text-center">
                            Remarks
                          </th>
                            <th class="text-center">
                              Date
                          </th>
                      </tr>
                  </thead>
                  <tbody>

                      <td colspan="12" v-if="plannersReference.length ==0 " class="pa-2 text-center">
                            <span style="font-size:20px" >  No Records Found!  </span>
                      </td>

                      <tr
                      
                      v-for="(item,i) in plannersReference"
                      :key="i"
                    
                      style="font-family:Arial"
                      >
                          <td width="8%" class="text-center">{{ item.houseType }}</td>
                          <td width="7%" class="text-center">{{ item.category1 }}</td>
                          <td width="7%" class="text-center">{{ item.category2}}</td>
                          <td width="8%" class="text-center">{{ item.doc_jap}}</td>
                          <td width="8%" class="text-center">{{ item.doc_romanji.toUpperCase()}}</td>
                          <td width="8%" class="text-center">{{ item.doc_english}}</td>
                          <td width="6%" class="text-center">
                              <span style="font-weight:bold;color:red"> {{ item.abbr}}</span>
                          </td>
                          <td width="13%" class="pa-5" align="center">
                              <v-tooltip top transition="scroll-y-transition">
                                  <template v-slot:activator="{on}">
                                      <v-img contain @click="openViewImageDialog(item,'CAD_REQUEST')" v-on="on" style="border:1px solid #c2c2c2;cursor:pointer;"  :src="item.base64Img_CadRequest" height="120" width="120" :lazy-src="`https://s3.us-east-2.amazonaws.com/rulebook.files/PlannersReference/loading-gif.gif`" > </v-img> 
                                  </template>
                                  <span style="font-family:Arial"> Click to view image </span>
                              </v-tooltip>
                          </td>
                          <td width="13%"  class="pa-5" align="center">
                              <v-tooltip top transition="scroll-y-transition">
                                  <template v-slot:activator="{on}">
                                    <v-img contain @click="openViewImageDialog(item,'FLOORPLAN_ELEVATION')"  v-on="on" style="border:1px solid #c2c2c2;cursor:pointer;" :src="item.base64Img_FloorElevation" height="120" width="120" :lazy-src="`https://s3.us-east-2.amazonaws.com/rulebook.files/PlannersReference/loading-gif.gif`"></v-img> 
                                  </template>
                                  <span style="font-family:Arial"> Click to view image </span>
                              </v-tooltip>
                              
                          </td>
                          <td width="15%" class="text-left" >{{ item.remarks}}</td>
                          <td width="7%" class="text-center" >{{ item.date}}</td>
                      </tr>
                  </tbody>
                  <!-- </template> -->
                </v-simple-table>
               <!-- </v-card> -->
             </a-row>
  </v-container>
  <!-- <center>
    <v-card width="1200">
      <editor
        :init=tiny_init
        v-model="test"
        height="1500"
        id="tinymce"
      />
    </v-card>
  </center>

  <span v-html="test" class="fr-view"></span> -->
</div>
  
</template>
<script>
// import Editor from "@tinymce/tinymce-vue";

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import 'tinymce/skins/content/dark/content.min.css';


// import 'tinymce/plugins/image'; // 插入上传图片插件
// import 'tinymce/plugins/media'; // 插入视频插件
// import 'tinymce/plugins/table'; // 插入表格插件
// import 'tinymce/plugins/lists'; // 列表插件
import 'tinymce/plugins/advlist';
// import 'tinymce/plugins/link'; // 列表插件
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/help';
import 'tinymce/plugins/table';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/template';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/toc';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/autosave';

import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

import axios from 'axios'

// import S3 from 'aws-s3'
import AWS from 'aws-sdk'
import tunnel from 'tunnel'
import moment from 'moment'

  export default {
    name: 'Home',
    components: {
      // 'editor': Editor
    },

    data(){
      return{
        plannersReference: [],
        tiny_init : {
          height: 700,
          selector: 'textarea#image-tools',
          menubar: false,
          noneditable_noneditable_class: 'mceNonEditable',
          
          plugins: [
            'code',
            'code noneditable',
            // 'export',
            'print',
            // 'pagebreak',
            // 'table',
            // 'advlist autolink lists link image charmap print preview anchor',
            // 'searchreplace visualblocks code fullscreen',
            // 'insertdatetime media table paste imagetools wordcount'
          ],
          // toolbar: 'code | export | print | pagebreak | table | fontselect  | fontsizeselect | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | insertfile undo redo',
          toolbar: 'code | print',
          // readonly: 1,
          
          content_style: [contentCss, contentUiCss].join('\n'),
          
          skin: false,
          // content_css: '/sample.css',
        },

        test : '',
        input: {},
      }
    }, //end of data
    mounted(){
      this.loadPaginationPlannerrReference();
              const listElm = document.querySelector('.v-data-table__wrapper'); 
        console.log( listElm )
        listElm.addEventListener('scroll', () => {
            console.log('scrolling na')
            // if( Math.ceil(listElm.scrollTop) + listElm.clientHeight >= listElm.scrollHeight) {
            //     console.log('trigger.. request api again')     
            // }
        });
    },
    created(){
      // this.toggleEditorMode(true)
      // this.getData()
      
    },
    computed: {
        screenSize() {
            return this.$vuetify.breakpoint.height;
        },
    },
    methods : {
       loadPaginationPlannerrReference() {
            // this.loadingPlannersReference = true 
            let count = 0;
            const url = `${this.api}plannersReference/paginate/plannersReference`
            axios.get(url).then( res =>{
                if( res.data ) {

                    this.lastEvaluatedKey = res.data.LastEvaluatedKey.id
                    // this.plannersReference = res.data.Items;

                    if( res.data.Items.length != 0  ) {

                       for( let item of res.data.Items ) {

                            var tunnelingAgent = tunnel.httpsOverHttp({
                            proxy: {
                                    // Proxy settings
                                    host: "hrdproxy.hrd-s.com",
                                    port: 81,
                                    proxyAuth: "administrator:system"
                                },
                            });
                                                  
                            AWS.config.update(
                            {
                                httpOptions: { agent: tunnelingAgent },
                                region: 'us-east-2', 
                                accessKeyId: this.accessKeyId,
                                secretAccessKey: this.secretAccessKey
                            });

                            let s3 = new AWS.S3({apiVersion: '2006-03-01'});

                            const params = {
                                Bucket: 'rulebook.files',
                                Key: `PlannersReference/${item.attachment_cadRequest}`
                            };

                            const params2 = {
                                Bucket: 'rulebook.files',
                                Key: `PlannersReference/${item.attachment_floorPlanElevation}`
                            };
                            
                            // CAD Request Image
                            s3.getObject( params ).promise().then( data =>{    
                                this.$set(item,'base64Img_CadRequest','data:image/jpeg;base64,' + Buffer.from(data.Body).toString('base64'))
                            }).catch(err=>{
                                console.log(`Error retrieving S3 Object ->  ${err}`);
                            })

                            // Floor Elevation Image
                            s3.getObject ( params2 ).promise().then( data2 => {                       
                                this.$set(item,'base64Img_FloorElevation','data:image/jpeg;base64,' + Buffer.from(data2.Body).toString('base64')) 
                            }).catch(err2=>{
                                console.log(`Error retrieving S3 Object -> ${err2}`);
                            })
                            
                            count++

                        } // END FOR LOOP
                    }
                    
                    if( count == res.data.Items.length ) {
                         this.plannersReference = res.data.Items;
                        //  this.loadingPlannersReference = false;

                    }
 

                   
                }
            }).catch(err=>{ 
                console.log(err.message);
                alert(`Error Fetching Planners Reference`);
            })
        },
      previewImage: function(event) {
        // Reference to the DOM input element     
            this.input = event.target;
            console.log('--->', this.input.files[0] )
            //   console.log('this.input.files[0]', this.input.files[0])
            // Ensure that you have a file before attempting to read it
            var reader = new FileReader();
              // Define a callback function to run, when FileReader finishes its job
              reader.onload = (e) => {
                  // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                  // Read image as base64 and set to imageData
                  this.imageData = e.target.result;
              }
              // Start the reader job - read file as a data url (base64 format)
              reader.readAsDataURL(this.input.files[0]);
      },
      async uploadFile(){
         // Adding Memorandum
         let excel_file = this.input.files[0];
        
               var tunnelingAgent = tunnel.httpsOverHttp({
                    proxy: {
                        // Proxy settings
                        host: "hrdproxy.hrd-s.com",
                        port: 81,
                        proxyAuth: "administrator:system",
                    },
               });

               await AWS.config.update(
                  {
                     httpOptions: { agent: tunnelingAgent },
                     region: 'us-east-2', 
                     accessKeyId: this.accessKeyId,
                     secretAccessKey: this.secretAccessKey
                  });
               
               let s3 = new AWS.S3({apiVersion: '2006-03-01'});

                ////CHANGE .vnd.ms-excel FILE EXTENSION TO .xls
                // let newFileName = pdf_file.name.replace(/.xls/g, '.vnd.ms-excel')
                
                 let newFileName = excel_file.name.replace(/.vnd.ms-excel/g, '.xls')
                 let ext = newFileName.split('.')[ newFileName.split('.').length -1 ]
                 let tmpFile = `1111111111-${moment().format("YYYYMMDDHHMMSS")}` // Temporary filename
                

                var uploadParams = {
                    Bucket: 'rulebook.files',
                    // Key: `Excel_files/${newFileName}`,
                    Key: `Excel_files/${tmpFile}.${ext}`,
                    Body: excel_file  // Object file na iuupload sa server 
                };

                await s3.upload (uploadParams, function (err, data) {
                    if (err) {
                        console.log("Error", err);
                    } if (data) {
                        console.log("Upload Success", data.Location);
                        alert(`Upload Success ${data.Location}`)
                    }
               });
      },

      getData(){
        axios.get(`${this.api}shiyo/get_shiyo/F2H9L3RR8?productName=ＴＶアンテナ`).then(res =>{
          // this.test = res.data.htmlContent.length
          // .split(',').length
          // .toString()
          // .replace(',', '<!-- pagebreak -->')
          console.log(res.data.htmlContent.length)
          let a = res.data.htmlContent
          let b = []
          for(let i = 0; i < a.length; i++){
            b.push(a[i]+'<!-- pagebreak -->')
          }


          this.test = `<div class="myclass mceNonEditable">`+ b.toString().replace('<!-- pagebreak -->,', '<!-- pagebreak -->')+`</div>`
        })
      }
    }
  }
</script>

<style scoped>
.mce-content-body{
  margin:0;
}
  .tox-notification {
      display: none !important;
  }

  .fr-view {
  font-family: "MS PGothic", Osaka, Arial, sans-serif;
  font-size: 18px;
}

.fr-view table.noborder tbody td {
 border: none;
}

.fr-view table.blackborder tbody td {
 border-color: black
}
.fr-view td.tableCellStyles1 {
 color:red
}

.fr-view span.rotateVerticalLeftRight {
 writing-mode: vertical-lr
}
.fr-view .class1 tbody tr:nth-child(2n) {
 background : #d3d6db
}
.fr-view .class2 thead tr th, .class2 tbody tr td {
 border-style: dashed
}
  

</style>
